<template>
  <div class="coupons-list">
    <div class="top-content">
      <div class="left">
        <el-button class="add-staff" type="primary" @click="handleAddClick(null)" v-has="'invite:add'">新增活动</el-button>
      </div>

    </div>
    <el-table :data="dataList" style="width: 100%;margin-bottom: 20px;">
      <el-table-column align="center" type="index" label="序号"/>
      <el-table-column align="center" prop="title" label="活动名称" />
      <el-table-column align="center" prop="promotionCount" label="邀请人数" />
      <el-table-column align="center" prop="newcomerCount" label="被邀请人数" />
      <el-table-column align="center" prop="distributionSituation" label="奖励发放情况" />
      <el-table-column align="center" prop="bindTime" label="活动时间" width="350px">
        <template #default="scope">
          {{scope.row.startTime}}至{{scope.row.endTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="bindTime" label="状态">
        <template #default="{row}">
          <el-popconfirm
            width="220"
            placement="top"
            confirm-button-text="确定"
            cancel-button-text="取消"
            :icon="InfoFilled"
            icon-color="#FA9904"
            title="是否确认修改当前状态?"
            @confirm="confirmEvent(row)"
            @cancel="cancelEvent"
          >
            <template #reference>
              <el-switch
                :model-value="row.enabled"
                style="--el-switch-on-color: #13ce66;"
              />
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" align="center" width="200px">
        <template #default="scope">
          <el-button type="text" size="small" @click="handleAddClick(scope.row.id)" v-has="'invite:edit'">编辑</el-button>
          <el-button type="text" size="small" style="color: #999;" @click="handleAddClick(scope.row.id,1)">详情</el-button>
          <el-button type="text" size="small" style="color: red;" @click="handleRemoveClick(scope.row.id)" v-has="'invite:delete'">删除</el-button>
          <el-button type="text" size="small" v-if="scope.row.enabled" @click="handleExtensionClick(scope.row.id)">推广</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
    :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
  <extensionDialog
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :itemId="itemId"
      @changeDialogVisible="changeDialogVisible"
    ></extensionDialog>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { getInvitingActivityList , removeInvitingActivity , enabledInvitingActivity } from '@/service/main/marketing'
import extensionDialog from './cpns/extension-dialog/extension-dialog.vue'
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    activeName: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageContent,
    extensionDialog
  },
  setup(props) {
    const router = useRouter()
    const queryInfo = reactive({
      page: 1,
      pagesize: 10
    })
    const dataCount = ref(0)
    const dataList = ref([])
    const lastTime = ref('')
    const handleAddClick = (id,type) => {
      if(id&&type){
        router.push({
          path: `/main/maketing/editMaketing`,
          query:{
            id,
            type
          }
        })
      }else if (id) {
        router.push({
          path: `/main/maketing/editMaketing`,
          query:{
            id
          }
        })
      }else{
        router.push({
          path: '/main/maketing/addMaketing'
        })
      }
      
    }
    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }
    const handleSizeChange = (pageSize) => {
      queryInfo.pagesize = pageSize
      initPage()
    }
    //删除
    const handleRemoveClick = (id) => {
      popup('确认删除该活动吗?', async() => {
        let res = await removeInvitingActivity(id)
        if (res.code==0) {
          ElMessage.success({
            message: '删除成功!',
            type: 'success'
          })
          initPage()
        }else{
          ElMessage.error({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
    const dialogVisible = ref(false)
    const itemId = ref(null)
    const handleExtensionClick = (id) => {
      dialogVisible.value = true
      itemId.value = id
    }
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }
    // 启用禁用
    const handleSwitchChange = (val, id) => {
      enabledInvitingActivity(id)
        .then((data) => {
          if (data.code==0) {
            if (val) {
              ElMessage.success('启用成功')
            } else {
              ElMessage.success('禁用成功')
            }
          }else{
            ElMessage.error(data.msg)
          }
          initPage()
        })
    }
    const confirmEvent = (row) => {
      let status = !row.enabled
      handleSwitchChange(status, row.id)
    }
    const refresh = flag => {
      initPage()
    }
    const initPage = async () => {
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize
      }
      const res = await getInvitingActivityList(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }
    initPage()

    return {
      dataList,
      handleRemoveClick,
      refresh,
      lastTime,
      handleAddClick,
      confirmEvent,
      handleCurrentChange,
      handleSizeChange,
      queryInfo,
      dataCount,
      handleExtensionClick,
      dialogVisible,
      changeDialogVisible,
      itemId
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .left {}

    .right {
      display: flex;

      .search-wrap {
        margin-left: 10px;
      }
    }

  }
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
  &:deep(.el-table) {
    // font-size: 12px;
    thead.is-group th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
  }
}
</style>
