<template>
  <div class="extension-dialog">
    <pageDialog
      title="推广"
      width="580px"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <div class="main-content">
        <div class="center">
          <img ref="poster" :src="ruleForm.link" alt="" />
          <el-button @click="downloadImage" type="primary">下载二维码</el-button>
        </div>
      </div>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,nextTick } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { TUI_GUANG_BAC_IMG, TUI_GUANG_BAC_IMG2 } from '@/common/constant'
import { getInvitingEqCode } from '@/service/main/marketing'
import html2canvas from "html2canvas";
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: String,
      default() {
        return null
      }
    }
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }
    const poster =ref();
    const posterimg = ref();
    const downloadImage = () => {
      //    document.querySelector() document.getElementById
      // html2canvas(绑定的元素,{})
      // 海报图默认是  2倍尺寸
      html2canvas(poster.value,{
          backgroundColor:'#f5f5f5',//海报的背景颜色
          useCORS:true, // 允许跨域 
          width:300, //生成海报的w
          height:320, //生成海报的h    默认是px
      }).then(canvas=>{
          // canvas 其实就是我们所讲的res 的意思 返回报文的意思
          let  baseImg = canvas.toDataURL("image/png");
          posterimg.value =baseImg;
          let save  = document.createElement('a');
          // <a href=''></a>
          save.href = baseImg;
          // 下载的名字
          save.download = '活动推广二维码'
          // 直接回调a的点击事件
          save.click()
      })
    }

    const ruleForm = reactive({
      link: 'https://blog.csdn.net/daguanjia11/article/details/119826175',

    })

    const initPage=async()=>{
      const res=await getInvitingEqCode(props.itemId)
      ruleForm.link=res.data
    }
    initPage()

    return {
      changeDialogVisible,
      TUI_GUANG_BAC_IMG,
      TUI_GUANG_BAC_IMG2,
      ruleForm,
      downloadImage,
      poster,
      posterimg,
    }

  }
})
</script>

<style scoped lang="less">
.extension-dialog {
  .main-content {
    .center{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // width: 300px;
      align-items: center;
      margin: 0 30px;
      &:deep(.el-button){
        margin-top: 20px;
        width: 150px;
      }
      img{
        width: 300px;
        height: 320px;
      }
    }
  }
}
</style>
