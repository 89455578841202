<template>
  <div class="coupons-list">
    <el-form ref="ruleFormRef" label-position="left" :model="ruleForm"
    class="demo-ruleForm" label-suffix="：" style="display: flex;height: 40px;margin-bottom: 20px;">
        <el-form-item prop="keyword" label="">
            <el-input v-model="keyword" placeholder="输入关键词" style="width: 200px;margin-right: 10px;"></el-input>
        </el-form-item>
        <el-button type="primary" @click="search">筛选</el-button>
        <el-button @click="reset">重置</el-button>
    </el-form>
    <el-table :data="dataList" style="width: 100%;margin-bottom: 20px;">
      <el-table-column align="center" prop="activityId" label="活动ID" />
      <el-table-column align="center" prop="activityName" label="活动名称" />
      <el-table-column align="center" prop="promotionUser" label="邀请人姓名/手机号">
        <template #default="{row}">
        {{ row.promotionUser }}（{{ row.promotionUserPhone }}）
        </template>
      </el-table-column>
      <el-table-column align="center" prop="newcomerUser" label="被邀请人姓名/手机号">
        <template #default="{row}">
        {{ row.newcomerUser }}（{{ row.newcomerUserPhone }}）
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="邀请时间" />
      <el-table-column align="center" prop="completeTime" label="领取时间" />
    </el-table>
    <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
    :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { useRouter } from 'vue-router'
import { getInvitingRecord } from '@/service/main/marketing'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    activeName: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageContent
  },
  setup(props) {
    const router = useRouter()
    const queryInfo = reactive({
      page: 1,
      pagesize: 10
    })
    const dataCount = ref(0)
    const keyword = ref(null)
    const dataList = ref([])
    const search = ()=>{
      queryInfo.keyword = keyword.value
      initPage()
    }
    const reset = ()=>{
      keyword.value = null
      queryInfo.keyword = null
      initPage()
    }
    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }
    const handleSizeChange = (pageSize) => {
      queryInfo.pagesize = pageSize
      initPage()
    }
    const initPage = async () => {
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize,
        keyword:queryInfo.keyword
      }
      const res = await getInvitingRecord(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }
    initPage()

    return {
      queryInfo,
      dataCount,
      dataList,
      keyword,
      search,
      reset,
      handleCurrentChange,
      handleSizeChange
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  // margin-top: 20px;
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
  &:deep(.el-table) {


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
  }
}
</style>
