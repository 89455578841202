<template>
    <div class="coupons-list">
      <breadcrumb :breadcrumbList="breadcrumbInvite"></breadcrumb>
      <div class="main-content">
        <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="活动列表" :name="0">
            <activityList></activityList>
          </el-tab-pane>
          <el-tab-pane label="邀请列表" :name="1">
            <inviteList></inviteList>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref } from 'vue'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import { breadcrumbInvite } from '../config'
  import { getServeBindList,unbindServe,bathUnbind } from "@/service/main/serve";
  import { useRouter } from 'vue-router'
  import { popup } from '@/utils/pop-up'
  import { ElMessage } from 'element-plus'
  import activityList from './cpns/activity-list/index.vue'
  import inviteList from './cpns/invite-list/index.vue'
  
  export default defineComponent({
    props: {
  
    },
    components: {
      Breadcrumb,
      activityList,
      inviteList
    },
    setup() {
      const router = useRouter()

      const tapPush = (url) =>{
        router.push(url)
      }
      const initPage = async () => {
        // const res = await getServeBindList({ ...queryInfo })
        // dataList.value = res.data.list
        // dataTotal.value = Number(res.data.total)
      }
      initPage()
  
  
  
      return {
        breadcrumbInvite,
        tapPush
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .coupons-list{
    height: calc(100vh - 90px);
  }
  .main-content {
    padding: 20px 80px 20px 20px;
    margin: 20px;
    background-color: #fff;
    .title{
      padding-top: 10px;
      margin-bottom: 40px;
    }
  }
  .box_item{
    width: 100%;
    height: 60px;
    background-color: #efefef;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 30px;
    cursor: pointer;
    .left{
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 6px;
    }
    img{
      width: 30px;
      height: 28px
    }
    .right{
      height: 42px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;
    }
    .right :first-child{
      font-weight: 500;
      font-size: 14px;
    }
  }
  </style>
  
  
  
  
  